import * as constants from './constants';

export const logIn = (email, password) => ({
  type: constants.LOG_IN,
  payload: {
    request: {
      url: 'token',
      method: 'post',
      data: {
        email,
        password
      }
    }
  }
});

export const logOut = () => ({
  type: constants.LOG_OUT
});

export const updateToken = (token, account=null) => ({
  type: constants.UPDATE_TOKEN,
  payload: {
    access: token,
    account
  }
});

export const register = (email, password1, password2) => ({
  type: constants.REGISTER,
  payload: {
    request: {
      url: '../../auth/registration/',
      method: 'post',
      data: {
        email,
        password1,
        password2
      }
    }
  }
});

export const forgottenPassword = (email) => dispatch => dispatch({
  type: constants.FORGOTTEN_PASSWORD,
  payload: {
    request: {
      url: '../../auth/password/reset/',
      method: 'post',
      data: {
        email
      }
    }
  }
});

export const resetPasswordConfirm = (token, uid, pwd1, pwd2) => dispatch => dispatch({
  type: constants.RESET_PASSWORD_CONFIRM,
  payload: {
    request: {
      url: '../../auth/password/reset/confirm/',
      method: 'post',
      data: {
        token,
        uid,
        new_password1: pwd1,
        new_password2: pwd2
      }
    }
  }
});

export const changePassword = (pwd1, pwd2) => dispatch => dispatch({
  type: constants.CHANGE_PASSWORD,
  payload: {
    request: {
      auth: true,
      url: '../../auth/password/change/',
      method: 'post',
      data: {
        new_password1: pwd1,
        new_password2: pwd2
      }
    }
  }
});


export const changeEmail = (newEmail) => dispatch => dispatch({
  type: constants.CHANGE_EMAIL,
  payload: {
    request: {
      auth: true,
      url: '../../auth/account/email/',
      method: 'post',
      data: {
        email: newEmail
      }
    }
  }
});

import jwt_decode from 'jwt-decode';

import { resolved, rejected } from 'api';
import * as constants from './constants';

const INITIAL_STATE = {
  access: null,
  expiry: -1,
  refresh: null,
  account: null,
  errors: null
};

export default (state=INITIAL_STATE, action) => {

  let data = null;
  let decoded = null;

  switch(action.type) {

    case resolved(constants.LOG_IN):
    case resolved(constants.REGISTER):
    case resolved(constants.CHANGE_EMAIL):

      data = action.payload.data;
      decoded = jwt_decode(data.access);

      return {
        errors: null,
        access: data.access,
        refresh: data.refresh,
        account: decoded.account || data.account,
        expiry: decoded.exp * 1000
      };

    case rejected(constants.LOG_IN):
      return {
        ...INITIAL_STATE,
        errors: action.error.response ? action.error.response.data : null
      };

    case rejected(constants.REGISTER):
      return {
        ...INITIAL_STATE,
        errors: action.error.response ? action.error.response.data : null
      };

    case constants.LOG_OUT:
      return { ...INITIAL_STATE };

    case constants.UPDATE_TOKEN:
      let access = action.payload.access;
      let decodedAccess = jwt_decode(access);

      return {
        ...state,
        access,
        account: action.payload.account || state.account,
        expiry: decodedAccess.exp * 1000
      };

    default:
      return state;

  }

};

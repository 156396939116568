import React from 'react';

import dayjs from 'dayjs';
import NoSubscription from './NoSubscription';
import { CancelSubscriptionButton } from './CancelSubscriptionButton';
import { DATE_FORMAT } from '../constants';
import SubscribeBox from './SubscribeBox';
import { ChangePaymentButton } from './ChangePaymentButton';

const PaymentMethodDescription = ({ paymentMethod }) => (
  <span>
    {
      paymentMethod.type === 'card' &&
      paymentMethod.card.brand !== 'unknown' &&
      `${paymentMethod.card.brand[0].toUpperCase()}${paymentMethod.card.brand.substring(1)} `
    }
    {paymentMethod.type}
    {
      paymentMethod.type === 'card' &&
      ` ending ${paymentMethod.card['last4']}`
    }
  </span>
);

const NextPaymentDetails = ({ paymentMethod, price, subscription }) => (
  <p>
    Your next payment of £{(price.unit_amount / 100).toFixed(2)} is due on {' '}
    {dayjs(subscription.current_period_end).format(DATE_FORMAT)} and will be
    charged to your <PaymentMethodDescription paymentMethod={paymentMethod} /> {' '}
    unless you add a new card or cancel your subscription before that date.
  </p>
);

export default ({ paymentMethod, price, subscription }) => {

  const willAutomaticallyRenew = !subscription.cancel_at_period_end &&
    !subscription.canceled_at;

  if (subscription.status === 'active') {
    return (
      <>
        <p>
          Your JustWords Premium subscription started on { ' ' }
          {dayjs(subscription['start_date']).format(DATE_FORMAT)}{' '}
          {
            willAutomaticallyRenew ?
              <span>
                and will automatically renew on{' '}
                {dayjs(subscription.current_period_end).format(DATE_FORMAT)}
              </span> :
              null
          }
          {
            !!subscription.cancel_at_period_end ?
              <span>
                and will not renew when it expires on {dayjs(subscription.current_period_end).format(DATE_FORMAT)}
              </span> :
              null
          }
          .
        </p>
        {
          willAutomaticallyRenew ? (
            <>
              <NextPaymentDetails
                paymentMethod={paymentMethod}
                price={price}
                subscription={subscription}
              />
            </>
           ) : null
        }
        <div className='d-flex justify-content-end align-items-center'>
          {
            paymentMethod && <ChangePaymentButton />
          }
          {
            willAutomaticallyRenew && <CancelSubscriptionButton subscription={subscription} />
          }
        </div>
      </>
    );
  }
  else if (subscription.ended_at) {
    return (
      <>
        <p>
          Your JustWords Premium subscription expired on { ' ' }
          {dayjs(subscription.ended_at).format(DATE_FORMAT)}. Resubscribe today
          and regain access to all features!
        </p>
        <SubscribeBox />
      </>
    );
  }
  else {
    return (
      <NoSubscription />
    );
  }
};
